import {useEffect,useRef,useState,useContext} from "react"
import FloatingContext from "../contexts/FloatingContext"
import useApi from "../hooks/useApi"
import useFloating from "../hooks/useFloating"
import ButtonAction from "../commons/ButtonAction"
import Datepicker from "../commons/Datepicker"
import Dropdown from "../commons/Dropdown"
import DropdownAsync from "../commons/DropdownAsync"
import SwitchButton from "../commons/SwitchButton"
import close from "../assets/close-pink.svg"

export default function ActivityFilters({optionsState, setOptionsState}){

    const {manageFloating,floatingReady}=useFloating()
    const [floatingContext]=useContext(FloatingContext)
    const requestApi=useApi()
    const value=floatingContext.value
    const setValue=floatingContext.setValue

    const filter1=useRef()
    const filter2=useRef()
    const filter3=useRef()
    const filter4=useRef()
    const filter5=useRef()
    const filter6=useRef()
    const filter7=useRef()
    const filter8=useRef()
    const filter9=useRef()
    const filter10=useRef()
    const filter11=useRef()
    const filter12=useRef()
    const filter13=useRef()
    const filter14=useRef()
    const filter15=useRef()
    const filter16=useRef()
    const filter17=useRef()
    const filter18=useRef()
    const filter19=useRef()
    const [filter1Val,setFilter1Val]=useState("")
    const [filter2Val,setFilter2Val]=useState("")
    const [filter3Val,setFilter3Val]=useState("")
    const [filter4Val,setFilter4Val]=useState("")
    const [filter5Val,setFilter5Val]=useState("")
    const [filter6Val,setFilter6Val]=useState("")
    const [filter7Val,setFilter7Val]=useState([])
    const [filter8Val,setFilter8Val]=useState([])
    const [filter9Val,setFilter9Val]=useState([])
    const [filter10Val,setFilter10Val]=useState([])
    const [filter11Val,setFilter11Val]=useState([])
    const [filter12Val,setFilter12Val]=useState([])
    const [filter13Val,setFilter13Val]=useState([])
    const [filter14Val,setFilter14Val]=useState([])
    const [filter15Val,setFilter15Val]=useState("")
    const [filter17Val,setFilter17Val]=useState([])
    const [filter18Val,setFilter18Val]=useState([])
    const [filter19Val,setFilter19Val]=useState([])

    
    const [paymentOptions,setPaymentOptions]=useState([])
    const [cardOptions,setCardOptions]=useState([])
    const [entityOptions,setEntityOptions]=useState([])
    const [cityOptions,setCityOptions]=useState([])
    const [regionOptions,setRegionOptions]=useState([])
    const [countryOptions,setCountryOptions]=useState([])

    const loadCards=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("payments/cards",{for_filter: true})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(card){
                            newOptions.push({label:card.name,value:card.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadEntities=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("payments/entities",{for_filter: true})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(entity){
                            newOptions.push({label:entity.name,value:entity.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadPayments=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("payments/list",{for_filter: true})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(payment){
                            newOptions.push({label:payment.name,value:payment.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    const searchUsers = (inputValue) =>

        new Promise(function(resolve){

            if(inputValue.length < 3) {
                return [];
            }

            resolve(
                requestApi("general/users_search", { search: inputValue })
                    .then(data => {
                        let newOptions = []
                        data.items.forEach(function(user) {
                            newOptions.push({ label: user.name, value: user.id })
                        })

                        return newOptions
                    })
            )
        })

    const loadCities=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("general/cities",{context:"activity"})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(city){
                            newOptions.push({label:city.name,value:city.name})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadRegions=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("general/regions",{context:"activity"})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(region){
                            newOptions.push({label:region.name,value:region.name})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadCountries=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("general/countries",{context:"activity"})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(country){
                            newOptions.push({label:country.name,value:country.name})
                        })
                        return newOptions
                    })
            )
        })
    }

    const updateOptionsState = (result) => {
        let optionsState = {
            loaded: true,
            paymentOptions: result[0],
            cardOptions: result[1],
            entityOptions: result[2],
            cityOptions: result[3],
            regionOptions: result[4],
            countryOptions: result[5]
        }
        setOptionsState(optionsState)
    }

    useEffect(() => {
        if(optionsState.loaded != true) {

            floatingReady(0)

            Promise.all([loadPayments,loadCards,loadEntities,loadCities,loadRegions,loadCountries].map(func=>func())).then((result)=>{
                setPaymentOptions(result[0])
                setCardOptions(result[1])
                setEntityOptions(result[2])
                setCityOptions(result[3])
                setRegionOptions(result[4])
                setCountryOptions(result[5])

                floatingReady(1)
                updateOptionsState(result)
            })
        } else {

            setPaymentOptions(optionsState.paymentOptions)
            setCardOptions(optionsState.cardOptions)
            setEntityOptions(optionsState.entityOptions)
            setCityOptions(optionsState.cityOptions)
            setRegionOptions(optionsState.regionOptions)
            setCountryOptions(optionsState.countryOptions)
        }
        
    },[optionsState])

    const apply=()=>{
        let filter8List=[]
        if(filter8.current.getValue()){
            filter8.current.getValue().forEach(function(item){
                filter8List.push(item["value"])
            })
        }
        let filter10List=[]
        if(filter10.current.getValue()){
            filter10.current.getValue().forEach(function(item){
                filter10List.push(item["value"])
            })
        }
        let filter11List=[]
        if(filter11.current.getValue()){
            filter11.current.getValue().forEach(function(item){
                filter11List.push(item["value"])
            })
        }
        let filter12List=[]
        if(filter12.current.getValue()){
            filter12.current.getValue().forEach(function(item){
                filter12List.push(item["value"])
            })
        }
        let filter13List=[]
        if(filter13.current.getValue()){
            filter13.current.getValue().forEach(function(item){
                filter13List.push(item["value"])
            })
        }
        let filter16List=[]
        if(filter16.current.getValue()){
            filter16.current.getValue().forEach(function(item){
                filter16List.push(item["value"])
            })
        }
        let filter17List=[]
        if(filter17.current.getValue()){
            filter17.current.getValue().forEach(function(item){
                filter17List.push(item["value"])
            })
        }
        let filter18List=[]
        if(filter18.current.getValue()){
            filter18.current.getValue().forEach(function(item){
                filter18List.push(item["value"])
            })
        }
        let filter19List=[]
        if(filter19.current.getValue()){
            filter19.current.getValue().forEach(function(item){
                filter19List.push(item["value"])
            })
        }
        setValue({
            period:filter1.current.getValue()[0]?filter1.current.getValue()[0].value:"",
            month:filter2.current.getValue()[0]?filter2.current.getValue()[0].value:"",
            year:filter3.current.getValue()[0]?filter3.current.getValue()[0].value:"",
            date:filter4Val,
            date_start:filter5Val,
            date_end:filter6Val,
            status:filter7Val,
            class:filter8List,
            payment_class:filter9Val,
            payment:filter10List,
            card:filter11List,
            entity:filter12List,
            installments:filter13List,
            origin:filter14Val,
            amount:filter15Val,
            user:filter16List,
            city:filter17List,
            region:filter18List,
            country:filter19List
        })
        manageFloating("hide")
    }

    const clear=()=>{
        setFilter1Val("")
        setFilter2Val("")
        setFilter3Val("")
        setFilter4Val("")
        setFilter5Val("")
        setFilter6Val("")
        setFilter7Val([])
        setFilter8Val([])
        setFilter9Val([])
        setFilter10Val([])
        setFilter11Val([])
        setFilter12Val([])
        setFilter13Val([])
        setFilter14Val([])
        setFilter15Val("")
        setFilter17Val([])
        setFilter18Val([])
        setFilter19Val([])
        filter1.current.setValue("")
        filter2.current.setValue("")
        filter3.current.setValue("")
        filter8.current.setValue("")
        filter10.current.setValue("")
        filter11.current.setValue("")
        filter12.current.setValue("")
        filter13.current.setValue("")
        filter16.current.setValue("")
        filter17.current.setValue("")
        filter18.current.setValue("")
        filter19.current.setValue("")
        setValue("")
        manageFloating("hide")
    }

    useEffect(()=>{
        if(filter1Val!="" && filter1Val!=undefined){
            restrict("period")
        }
    },[filter1Val])

    useEffect(()=>{
        if((filter2Val!="" && filter2Val!=undefined) || (filter3Val!="" && filter3Val!=undefined)){
            restrict("month_year")
        }
    },[filter2Val])

    useEffect(()=>{
        if((filter2Val!="" && filter2Val!=undefined) || (filter3Val!="" && filter3Val!=undefined)){
            restrict("month_year")
        }
    },[filter3Val])

    useEffect(()=>{
        if(filter4Val!=""){
            restrict("date")
        }
    },[filter4Val])

    useEffect(()=>{
        if(filter5Val!="" || filter6Val!=""){
            restrict("interval")
        }
    },[filter5Val])

    useEffect(()=>{
        if(filter5Val!="" || filter6Val!=""){
            restrict("interval")
        }
    },[filter6Val])

    const restrict=(filter)=>{
        if(filter=="period"){
            setFilter2Val("")
            filter2.current.setValue("")
            setFilter3Val("")
            filter3.current.setValue("")
            setFilter4Val("")
            setFilter5Val("")
            setFilter6Val("")
        }else if(filter=="month_year"){
            setFilter1Val("")
            filter1.current.setValue("")
            setFilter4Val("")
            setFilter5Val("")
            setFilter6Val("")
        }else if(filter=="date"){
            setFilter1Val("")
            filter1.current.setValue("")
            setFilter2Val("")
            filter2.current.setValue("")
            setFilter3Val("")
            filter3.current.setValue("")
            setFilter5Val("")
            setFilter6Val("")
        }else if(filter=="interval"){
            setFilter1Val("")
            filter1.current.setValue("")
            setFilter2Val("")
            filter2.current.setValue("")
            setFilter3Val("")
            filter3.current.setValue("")
            setFilter4Val("")
        }
    }

    useEffect(()=>{
        if(value!=""){
            if(value["period"]!=""){
                filter1.current.props["options"].forEach(function(item){
                    if(value["period"]==item.value){
                        filter1.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["month"]!=""){
                filter2.current.props["options"].forEach(function(item){
                    if(value["month"]==item.value){
                        filter2.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["year"]!=""){
                filter3.current.props["options"].forEach(function(item){
                    if(value["year"]==item.value){
                        filter3.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["date"]!=""){
                setFilter4Val(value["date"])
            }
            if(value["date_start"]!=""){
                setFilter5Val(value["date_start"])
            }
            if(value["date_end"]!=""){
                setFilter6Val(value["date_end"])
            }
            if(value["status"].length>0){
                setFilter7Val(value["status"])
            }
            if(value["class"].length>0){
                let newValue=[];
                filter8.current.props["options"].forEach(function(item){
                    if(value["class"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter8.current.setValue(newValue)
            }
            if(value["payment_class"].length>0){
                setFilter9Val(value["payment_class"])
            }
            if(value["installments"].length>0){
                let newValue=[];
                filter13.current.props["options"].forEach(function(item){
                    if(value["installments"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter13.current.setValue(newValue)
            }
            if(value["origin"].length>0){
                setFilter14Val(value["origin"])
            }
            if(value["amount"]!=""){
                setFilter15Val(value["amount"])
            }
        }
    },[value])

    useEffect(()=>{
        if(value){
            if(value["payment"].length>0){
                let newValue=[];
                filter10.current.props["options"].forEach(function(item){
                    if(value["payment"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter10.current.setValue(newValue)
            }
        }
    },[paymentOptions])

    useEffect(()=>{
        if(value){
            if(value["card"].length>0){
                let newValue=[];
                filter11.current.props["options"].forEach(function(item){
                    if(value["card"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter11.current.setValue(newValue)
            }
        }
    },[cardOptions])

    useEffect(()=>{
        if(value){
            if(value["entity"].length>0){
                let newValue=[];
                filter12.current.props["options"].forEach(function(item){
                    if(value["entity"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter12.current.setValue(newValue)
            }
        }
    },[entityOptions])

    useEffect(()=>{
        if(value){
            if(value["city"].length>0){
                let newValue=[];
                filter17.current.props["options"].forEach(function(item){
                    if(value["city"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter17.current.setValue(newValue)
            }
        }
    },[cityOptions])

    useEffect(()=>{
        if(value){
            if(value["region"].length>0){
                let newValue=[];
                filter18.current.props["options"].forEach(function(item){
                    if(value["region"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter18.current.setValue(newValue)
            }
        }
    },[regionOptions])

    useEffect(()=>{
        if(value){
            if(value["country"].length>0){
                let newValue=[];
                filter19.current.props["options"].forEach(function(item){
                    if(value["country"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter19.current.setValue(newValue)
            }
        }
    },[countryOptions])

    return(
        <div className="floating-blocks">
            <div className="floating-close-frame">
                <div className="floating-close" onClick={()=>{manageFloating("hide")}}><img src={close} /></div>
            </div>
            <div className="floating-content">
                <div className="floating-group">Fechas</div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter1} val={filter1Val} setVal={setFilter1Val} placeholder="Período" multiple={0} searchable={0} options={[
                            {label:"Indiferente",value:""},
                            {label:"Última hora",value:"last_hour"},
                            {label:"Hoy",value:"today"},
                            {label:"Ayer",value:"yesterday"},
                            {label:"Última semana",value:"last_week"},
                            {label:"Últimos quince días",value:"last_15_days"},
                            {label:"Este mes",value:"this_month"},
                            {label:"Últimos tres meses",value:"last_three_months"},
                            {label:"Últimos seis meses",value:"last_six_months"},
                            {label:"Este año",value:"this_year"}
                        ]} />
                    </div>
                    <div className="floating-half" >
                        <Dropdown ref={filter2} val={filter2Val} setVal={setFilter2Val} placeholder="Mes" multiple={0} searchable={0} options={[
                            {label:"Todos",value:""},
                            {label:"Enero",value:"01"},
                            {label:"Febrero",value:"02"},
                            {label:"Marzo",value:"03"},
                            {label:"Abril",value:"04"},
                            {label:"Mayo",value:"05"},
                            {label:"Junio",value:"06"},
                            {label:"Julio",value:"07"},
                            {label:"Agosto",value:"08"},
                            {label:"Septiembre",value:"09"},
                            {label:"Octubre",value:"10"},
                            {label:"Noviembre",value:"11"},
                            {label:"Diciembre",value:"12"}
                        ]} />
                        <Dropdown ref={filter3} val={filter3Val} setVal={setFilter3Val} placeholder="Año" multiple={0} searchable={0} options={[
                            {label:"Todos",value:""},
                            {label:"2022",value:"2019"},
                            {label:"2023",value:"2023"},
                            {label:"2024",value:"2024"},
                            {label:"2025",value:"2025"},
                            {label:"2026",value:"2026"},
                            {label:"2027",value:"2027"},
                            {label:"2028",value:"2028"},
                            {label:"2029",value:"2029"},
                            {label:"2030",value:"2030"},
                            {label:"2031",value:"2031"},
                            {label:"2032",value:"2032"},
                            {label:"2033",value:"2033"},
                            {label:"2034",value:"2034"},
                            {label:"2034",value:"2034"}
                        ]} />
                    </div>
                    <div className="floating-full">
                        <Datepicker ref={filter4} val={filter4Val} setVal={setFilter4Val} label="Fecha" />
                    </div>
                </div>
                <div className="floating-group">Intervalo</div>
                <div className="floating-options">
                    <div className="floating-half" >
                        <Datepicker ref={filter5} val={filter5Val} setVal={setFilter5Val} label="Desde" />
                        <Datepicker ref={filter6} val={filter6Val} setVal={setFilter6Val} label="Hasta" />
                    </div>
                </div>
                <div className="floating-group">Estado</div>
                <div className="floating-options">
                    <div className="floating-half">
                        <SwitchButton ref={filter7} val={filter7Val} setVal={setFilter7Val} multiple={true}
                            options={[
                                {label:"Aprobadas",value:"approved"},
                                {label:"Pendientes",value:"pending"},
                                {label:"Devueltas",value:"refund"},
                                {label:"Devueltas parc.",value:"partial_refund"},
                                {label:"Canceladas",value:"canceled"},
                                {label:"Rechazadas",value:"rejected"},
                                {label:"Liquidadas",value:"liquidated"},
                                {label:"Sin liquidar",value:"non_liquidated"},
                                {label:"Contracargadas",value:"countercharged"}
                        ]} />
                    </div>
                </div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter8} val={filter8Val} setVal={setFilter8Val} placeholder="Tipo de transacción" multiple={1} searchable={0} options={[
                            {label:"Pagos",value:"payment"},
                            {label:"Devoluciones",value:"refund"},
                            {label:"Devoluciones parciales",value:"partial_refund"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Forma de pago</div>
                <div className="floating-options">
                    <div className="floating-half">
                        <SwitchButton ref={filter9} val={filter9Val} setVal={setFilter9Val} multiple={true}
                            options={[
                                {label:"Crédito",value:"credit"},
                                {label:"Débito",value:"debit"},
                                {label:"Prepaga",value:"prepaid"},
                                {label:"Debin",value:"debin"}
                        ]} />
                    </div>
                </div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter10} val={filter10Val} setVal={setFilter10Val} placeholder="Medio de pago" multiple={1} searchable={0} options={paymentOptions} />
                    </div>
                </div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter11} val={filter11Val} setVal={setFilter11Val} placeholder="Tarjeta" multiple={1} searchable={0} options={cardOptions} />
                        <Dropdown ref={filter12} val={filter12Val} setVal={setFilter12Val} placeholder="Entidad" multiple={1} searchable={0} options={entityOptions} />
                    </div>
                </div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter13} val={filter13Val} setVal={setFilter13Val} placeholder="Cantidad de cuotas" multiple={1} searchable={0} options={[
                            {label:"1 cuota",value:"1"},
                            {label:"2 cuotas",value:"2"},
                            {label:"3 cuotas",value:"3"},
                            {label:"4 cuotas",value:"4"},
                            {label:"5 cuotas",value:"5"},
                            {label:"6 cuotas",value:"6"},
                            {label:"7 cuotas",value:"7"},
                            {label:"8 cuotas",value:"8"},
                            {label:"9 cuotas",value:"9"},
                            {label:"10 cuotas",value:"10"},
                            {label:"11 cuotas",value:"11"},
                            {label:"12 cuotas",value:"12"},
                            {label:"13 cuotas",value:"13"},
                            {label:"14 cuotas",value:"14"},
                            {label:"15 cuotas",value:"15"},
                            {label:"16 cuotas",value:"16"},
                            {label:"17 cuotas",value:"17"},
                            {label:"18 cuotas",value:"18"},
                            {label:"19 cuotas",value:"19"},
                            {label:"20 cuotas",value:"20"},
                            {label:"21 cuotas",value:"21"},
                            {label:"22 cuotas",value:"22"},
                            {label:"23 cuotas",value:"23"},
                            {label:"24 cuotas",value:"24"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Origen</div>
                <div className="floating-options">
                    <div className="floating-half">
                        <SwitchButton ref={filter14} val={filter14Val} setVal={setFilter14Val} multiple={true}
                            options={[
                                {label:"Webpos",value:"webpos"},
                                {label:"Links de pago",value:"link"},
                                {label:"Integraciones",value:"integration"},
                                {label:"Pagos con QR",value:"qr"},
                                {label:"Suscripciones",value:"subscription"},
                                {label:"Backend",value:"backend"},
                                {label:"WooCommerce",value:"externalCheckout"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Monto</div>
                <div className="floating-options">
                    <div className="floating-half">
                        <SwitchButton ref={filter15} val={filter15Val} setVal={setFilter15Val} 
                            options={[
                                {label:"$10~500",value:"10_500"},
                                {label:"$500~1000",value:"500_1000"},
                                {label:"$1000~2500",value:"1000_2500"},
                                {label:"$2500~5000",value:"2500_5000"},
                                {label:"$5000~10000",value:"5000_10000"},
                                {label:"$10000~15000",value:"10000_15000"},
                                {label:"$15000~25000",value:"15000_25000"},
                                {label:"+$25000",value:"25000"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Usuario</div>
                <div className="floating-options">
                    <div className="floating-full">
                        <DropdownAsync ref={filter16} loadOptions={searchUsers} placeholder="Nombre y/o apellido del usuario" multiple={1} />
                    </div>
                </div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter17} val={filter17Val} setVal={setFilter17Val} placeholder="Ciudad" multiple={1} searchable={1} options={cityOptions} />
                        <Dropdown ref={filter18} val={filter18Val} setVal={setFilter18Val} placeholder="Provincia" multiple={1} searchable={1} options={regionOptions} />
                        <Dropdown ref={filter19} val={filter19Val} setVal={setFilter19Val} placeholder="País" multiple={1} searchable={0} options={countryOptions} />
                    </div>
                </div>
            </div>
            <div className="floating-actions">
                <ButtonAction label="Resetear" color="pink" clickEvent={clear} />
                <ButtonAction label="Aplicar" color="purple" clickEvent={apply} />
            </div>
        </div>
    )
}